<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <v-img
      class="misc-tree"
      src="@/assets/images/misc/tree-4.png"
    ></v-img>

    <div class="page-title text-center px-5">

      <h1 class="text-5xl font-weight-semibold text--primary d-flex align-center justify-center">
        <v-icon color="warning" x-large>
          {{ icons.mdiAlert }}
        </v-icon>
        <span class="me-2">GENERATING SAVINGS INTEREST</span>
        <v-icon color="warning" x-large>
          {{ icons.mdiAlert }}
        </v-icon>
      </h1>
      <p class="text-xl">
        PLEASE DON'T CLICK RELOAD THE PAGE OR BACK BUTTON (AFTER GENERATING IT WILL REDIRECT YOU TO
        DASHBOARD)
      </p>

      <div class="misc-character d-flex justify-center">
        <v-progress-circular
          :rotate="90"
          :size=250
          :width="15"
          indeterminate
          color="primary"
        >
        </v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
  import {mdiAlert} from '@mdi/js'
  import {mapActions, mapGetters} from "vuex";

  export default {
    setup() {
      return {
        interval: {},
        icons: {
          mdiAlert,
        },
      }
    },
    beforeDestroy() {
      clearInterval(this.interval)
    },
    watch: {
      is_generating_savings_interest: function () {
        if (this.is_generating_savings_interest === 0) {
          location.reload()
        }
      }
    },
    mounted() {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const data = new FormData()
        data.append('month_of', this.month_of);
        data.append('branch_id', this.branch_id);
        this.insert_interest_regular_savings(data)
          .then(response => {
            if (response.data === 'DONE') {
              this.change_is_generating_savings_interest(0)
              location.reload()
            }
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
          })
      }, 1000)
    },
    computed: {
      ...mapGetters('authentication', ['branch_id', 'is_generating_savings_interest', 'month_of']),
    },
    methods: {
      ...mapActions('regular_savings_data', ['insert_interest_regular_savings']),
      ...mapActions('authentication', ['change_is_generating_savings_interest']),
    },
  }
</script>

<style lang="scss">
  @import '~@/plugins/vuetify/default-preset/preset/misc.scss';
</style>
